<template>
  <section class="content">
        <!-- <loading :active.sync="isLoading"
        :color="'#39a2db'"
        :loader="'dots'"
        :is-full-page="true"></loading> -->
        <div class="row">
          <div class="col-12">
            <div class="container-fluid">
              <div class="rowx">
                <div class="col-sm-8 offset-sm-2 d-flex flex-column justify-content-center align-items-center">
                  <h2>Tes Kecermatan - {{ ujian_pass }}</h2>
                  <h2 class="text-success text-bold">Selesai</h2>
                  <span class="text-center">Kamu telah menyelesaikan <span class="text-bold">Tes Kecermatan - {{ujian_pass}}. </span> Setelah ini otomatis akan dilanjutkan menuju <span class="text-bold">Tes Kecermatan - {{ujian_next}}.</span>  Silahkan persiapkan diri untuk tes berikutnya.</span>
                  <h1 class="text-danger text-bold mt-3" style="font-size: 120px;">{{countdown}}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
  </section>
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  name: 'UjianBreak',
  data() {
    return {
      isLoading: false,
      ujian_pass : '',
      ujian_next : '',
      countdown : 10
    };
  },

  mounted() {
      var query_params = this.$route.query
      this.ujian_next = query_params.next_ujian
      this.ujian_pass = query_params.pass_ujian
      console.log(query_params)
      if (!query_params.next_ujian) {
          this.$router.push({path: '/rekap-hasil-kecermatan', query: {ujian_code: query_params.ujian_code}})
          sessionStorage.setItem('is_finish', 'reload');
      }

      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1
        } else {
          sessionStorage.setItem('is_next_ujian', 'reload');
          sessionStorage.removeItem('soals');
          sessionStorage.removeItem('soal_saat_ini');
          sessionStorage.removeItem('jawaban');
          this.$router.push("/ujian-process-kecermatan/"+query_params.psi_kecermatan_index+"/"+ query_params.ujian_code);
        }
      }, 1000)
  }
}
</script>

<style>

</style>
